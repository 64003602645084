import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const AIVOICE2Cards = ({limit}) =>
{
  const adItems = 
  [
    "VJ01001125",//琴葉 茜・葵
    "VJ01001126",//伊織弓鶴
    "VJ01001127",//結月ゆかり
    "VJ01001128",//紲星あかり
    "VJ01002083",//つくよみちゃん
    "VJ01002184",//GUMI
    "VJ01002236",//タンゲコトエ
    "VJ01002152",//カキョウヨサリ
    "VJ01002153",//蓮鬼ねむ
    "VJ01002175",//紡乃世詞音
    "VJ01002932",//RIA
    "VJ01002933",//青山龍星
    "VJ01002934",//夜語トバリ
    "VJ01003067",//式狼縁・式大元
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default AIVOICE2Cards

